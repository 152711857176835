import React, {Component} from 'react';
import Ezlinavis from './components/EzlinavisComponent';
import './App.css';

class App extends Component {
  render () {
    return (
      <div className="index">
        <Ezlinavis/>
      </div>
    );
  }
}

export default App;
